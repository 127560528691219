import { atom } from 'recoil'

export interface FiltersStateT {
  startDate: Date | null
  endDate: Date | null
  search: string
}

export let filters_state = atom<FiltersStateT>({
  key: 'date_range',
  
  default: {
  //@ts-expect-error
    startDate: (new Date().getTime()),
    endDate: null,
    search: '',
  },
})
