import React, { useState } from 'react'
import {
  IonInput,
  IonButton,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
  IonText,
} from '@ionic/react'
import { lockClosedOutline } from 'ionicons/icons'
import { useRecoilState } from 'recoil'
import './LoginScreen.css'
import { UserStateT, user_state } from '../../states/user.state'
import { http } from '../../helpers/http'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import Loader from '../../components/Loader'
import logo from '../../assets/logo.png'

const LoginScreen: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [, setUser] = useRecoilState(user_state)
  const [error, setError] = useState<string>('')
  const history = useHistory()

  const loginMutation = useMutation<{ data: UserStateT }, Error, { email: string; password: string }>(
    ({ email, password }) => http.post('/login', { email, password }),
    {
      onSuccess: ({ data }) => {
        setUser(data)
        history.replace('/')
      },
      onError: (error) => {
        // @ts-expect-error
        setError(error.response.data.message)
      },
    },
  )

  const handleEmailChange = (e: CustomEvent) => {
    setEmail(e.detail.value || '')
  }

  const handlePasswordChange = (e: CustomEvent) => {
    setPassword(e.detail.value || '')
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    loginMutation.mutate({ email, password })
  }

  return (
    <IonContent>
      <div className="login-container">
        <IonGrid>
          <IonRow className="ion-align-items-center ion-justify-content-center">
            <IonCol size="6">
              <div className="login-header">
                <img src={logo} alt="" />
              </div>
              <form onSubmit={handleFormSubmit}>
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput type="email" value={email} onIonChange={handleEmailChange} required />
                </IonItem>
                <IonItem>
                  <IonLabel position="floating">Mot de passe</IonLabel>
                  <IonInput type="password" value={password} onIonChange={handlePasswordChange} required />
                </IonItem>
                {error && (
                  <div className="center">
                    <IonText color="danger">{error}</IonText>
                  </div>
                )}

                <IonButton type="submit" expand="block" fill="solid" color="primary" disabled={loginMutation.isLoading}>
                  {loginMutation.isLoading ? <Loader /> : 'Se connecter'}
                  <IonIcon slot="start" icon={lockClosedOutline} />
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonContent>
  )
}

export default LoginScreen
