import { Redirect, Route, Switch } from 'react-router-dom'
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { logOutOutline, cashOutline, settingsOutline } from 'ionicons/icons'
import { useRecoilState } from 'recoil'
import TransactionsTab from './screens/TransactionsTab'
import MonitoringTab from './screens/MonitoringTab'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './App.css'
import LoginScreen from './screens/LoginScreen/LoginScreen'
import { user_state } from './states/user.state'
import EventTab from './screens/EventTab'

setupIonicReact()

const App: React.FC = () => {
  const [user, set_user] = useRecoilState(user_state)

  return (
    <IonApp>
      <IonReactRouter>
        <Switch>
          <Route path="/login">
            <LoginScreen />
          </Route>
          {user ? (
            <IonTabs>
              <IonRouterOutlet>
                <Route exact path="/transactions">
                  <TransactionsTab />
                </Route>

                <Route exact path="/monitoring">
                  <MonitoringTab />
                </Route>

                <Route exact path="/events">
                  <EventTab />
                </Route>

                <Route exact path="/">
                  <Redirect to="/transactions" />
                </Route>
              </IonRouterOutlet>

              <IonTabBar slot="bottom">
                <IonTabButton tab="transactions" href="/transactions">
                  <IonIcon aria-hidden="true" icon={cashOutline} />
                  <IonLabel>Transactions</IonLabel>
                </IonTabButton>
                <IonTabButton tab="monitoring" href="/monitoring">
                  <IonIcon aria-hidden="true" icon={settingsOutline} />
                  <IonLabel>Monitoring</IonLabel>
                </IonTabButton>
                <IonTabButton tab="events" href="/events">
                  <IonIcon aria-hidden="true" icon={settingsOutline} />
                  <IonLabel>Evènements</IonLabel>
                </IonTabButton>
                <IonTabButton tab="faq" href="/faq">
                  <IonIcon name="help-circle-outline"></IonIcon>
                  <IonLabel>FAQ</IonLabel>
                </IonTabButton>

                <IonTabButton
                  onClick={() => {
                    set_user(null)
                  }}
                  tab="logout"
                  href="/"
                >
                  <IonIcon color="danger" aria-hidden="true" icon={logOutOutline} />
                  <IonLabel color="danger">Se déconnecter</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          ) : (
            <Redirect to="/login" />
          )}
        </Switch>
      </IonReactRouter>
    </IonApp>
  )
}

export default App
