import 'react-datepicker/dist/react-datepicker.css'
import './Transactions.css'
import React, { memo, useMemo, useState } from 'react'
import { TransactionT } from '../helpers/types'
import classnames from 'classnames'
import lodash from 'lodash'
import Loader from './Loader'
import { http, useAuthQuery } from '../helpers/http'
import { useRecoilState } from 'recoil'
import { filters_state } from '../states/filters.state'
import { user_state } from '../states/user.state'
import { IonToast } from '@ionic/react'
import { IonIcon } from '@ionic/react'

import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
} from '@ionic/react'

type TransactionRowProps = {
  transaction: TransactionT
  onRowClick: (transaction: TransactionT) => void
}
let TransactionRow: React.FC<TransactionRowProps> = React.memo(({ transaction, onRowClick }) => {
  return (
    <tr key={transaction.id}>
      <td>{new Date(transaction.HORODATAGE.seconds * 1000).toLocaleString()}</td>
      <td>{transaction.machine_location}</td>
      <td className="right bold weight">{transaction.POIDS_VENTE} kg</td>
      <td
        className={classnames(
          {
            bg_danger: transaction.PRIX_TTC === 0,
          },
          'right',
          'money',
        )}
      >
        {transaction.PRIX_TTC.toFixed(2)} €
      </td>
      <td className="right bold weight">{transaction.DUREE} s</td>
      <td className="right bold weight">{transaction.NUM_CB} </td>
      <td
        className="center"
        style={{
          cursor: 'pointer',
        }}
        onClick={() => onRowClick(transaction)}
      >
        <i className="fa-solid fa-file-invoice-dollar"></i>
      </td>
    </tr>
  )
})

let Transactions: React.FC = memo(() => {
  let [showToast, setShowToast] = useState(false)
  let [toastMessage, setToastMessage] = useState('')

  let [user] = useRecoilState(user_state)
  let [{ startDate, endDate, search }] = useRecoilState(filters_state)

  let {
    isLoading,
    isError,
    data: transactions,
  } = useAuthQuery<{ transactions: TransactionT[] }>(
    'transactions',
    `/transactions?startDate=${startDate}&endDate=${endDate}`,
  )

  let {
    isLoading: isLoadingTotals,
    // isError: isErrorTotals,
    data: transactionsTotals,
  } = useAuthQuery<{ totalAmount: number; totalTransactions: number }>('transactions-totals', `/transactions-totals`)

  let [transactionToShow, setTransactionToShow] = useState<any>(null)
  let [sending, setSending] = useState(false)
  let [email, setEmail] = useState()
  let [isOpened, setIsOpened] = useState(false)

  // let setCaPrev = async () => {
  //   await http.get('/caPrev', {
  //     headers: {
  //       Authorization: `Bearer ${user?.token}`,
  //     },
  //   })
  //   console.log('Appel fonction', setCaPrev)
  // }

  let onRowClick = (transaction) => {
    setTransactionToShow(transaction)
    setIsOpened(true)
  }

  let sendEmail = async () => {
    setSending(true)
    try {
      await http.post(
        '/email',
        {
          ticket: transactionToShow.TICKET,
          email,
          user: {
            name: user.profile.NOM,
            email: user.profile.EMAIL,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        },
      )
      setToastMessage('Email envoyé avec succès 🎉')
    } catch (error) {
      setToastMessage("Erreur lors de l'envoi de l'email 😢")
    } finally {
      setSending(false)
      setIsOpened(false)
      setShowToast(true) // Show the toast
    }
  }
  let [sort, setSort] = useState('CA-Asc')
  let [sortLoc, setSortLoc] = useState('Loc-Asc')
  let sortIcon =
    sort === 'CA-Asc' ? (
      <IonIcon name="arrow-down-circle-outline"></IonIcon>
    ) : (
      <IonIcon name="arrow-up-circle-outline"></IonIcon>
    )

  // let sortLocFunc =
  //   sortLoc === 'Loc-Asc' ? (
  //     <IonIcon name="arrow-down-circle-outline"></IonIcon>
  //   ) : (
  //     <IonIcon name="arrow-up-circle-outline"></IonIcon>
  //   )

  let transactions_in_range = useMemo(() => {
    let filteredTransactions = transactions?.transactions
    // if (startDate && endDate) {
    //   let afterEndDate = new Date(endDate).setDate(endDate.getDate() + 1)
    //   filteredTransactions = transactions?.filter((t: TransactionT) => {
    //     const transactionDate = new Date(t.HORODATAGE.seconds * 1000)
    //     return isWithinInterval(transactionDate, { start: startDate, end: afterEndDate })
    //   })
    // }

    if (search) {
      filteredTransactions = filteredTransactions?.filter((t: TransactionT) => {
        return t.machine_location?.toLowerCase().includes(search?.toLowerCase())
      })
    }

    // sort by HORODATAGE
    filteredTransactions = filteredTransactions?.sort((a: any, b: any): any => {
      return new Date(b.HORODATAGE.seconds * 1000).getTime() - new Date(a.HORODATAGE.seconds * 1000).getTime()
    })

    return filteredTransactions
  }, [transactions, search])

  let transactions_by_machine = useMemo(
    () => lodash.groupBy(transactions_in_range, 'machine_location'),
    [transactions_in_range],
  )

  if (isLoading) {
    return <Loader />
  }

  if (isError || !transactions) {
    return <div>Erreur</div>
  }

  return (
    <div className="tab_container container">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000} // Change this value to control how long the toast shows for
      />
      <div className="table_container">
        <table>
          <thead>
            <tr>
              <th colSpan={7} className="center">
                <h5>Opérations individuelles</h5>
              </th>
              {/* <th onClick={() => {}}> export </th> */}
            </tr>
            <tr>
              <th>Date</th>
              <th>Localisation</th>
              <th className="right">Poids</th>
              <th className="right">Montant</th>
              <th className="right">Durée</th>
              <th className="right"># CB</th>
              <th className="center">Ticket</th>
            </tr>
          </thead>
          <tbody>
            {transactions_in_range?.map((t: TransactionT) => {
              return <TransactionRow key={t.id} transaction={t} onRowClick={onRowClick} />
            })}
          </tbody>
        </table>
      </div>

      {/* Revenue */}
      <div className="table_container">
        <table>
          <thead>
            <tr>
              <th colSpan={4} className="center">
                <h5> Cumul C.A.</h5>
              </th>
            </tr>
            <tr>
              <th># Terminal </th>
              <th
                onClick={() => {
                  setSortLoc(sortLoc === 'Loc-Asc' ? 'Loc-Des' : 'Loc-Asc')
                }}
              >
                Localisation {sortIcon}
              </th>
              <th
                className="center"
                onClick={() => {
                  setSort(sort === 'CA-Asc' ? 'CA-Des' : 'CA-Asc')
                }}
              >
                C.A. / Machine {sortIcon}
              </th>
              <th className="center">C.A. 2023 </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(transactions_by_machine)
              .sort((a: any, b: any) => {
                let machineA = transactions_by_machine[a].reduce((acc, curr) => acc + curr.PRIX_TTC, 0)
                let machineB = transactions_by_machine[b].reduce((acc, curr) => acc + curr.PRIX_TTC, 0)

                if (machineA > machineB) {
                  return sort === 'CA-Asc' ? -1 : 1
                } else return sort === 'CA-Asc' ? 1 : -1
              })
              .map((key: string) => {
                let machine = transactions_by_machine[key][0]

                return (
                  <tr key={machine.id}>
                    <td>{machine.machine_id}</td>
                    <td>{machine.machine_location}</td>
                    <td className="right money">
                      {transactions_by_machine[key]
                        .reduce((acc, curr) => acc + curr.PRIX_TTC, 0)
                        .toFixed(2)
                        .toLocaleString()}{' '}
                      €
                    </td>
                    <td className="right money">{machine?.caprevious?.toFixed(0).toLocaleString()} €</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
      <div
        // style={{
        //   display: 'flex',
        //   flexDirection: 'column',
        //   justifyContent: 'flex-end',
        // }}
      >
        <div className="table_container big">
          <table>
            <thead>
              {/* <tr>
                <th>Total C.A.</th>
              </tr> */}
              <tr>
                <th className="right">C.A. de la période selectionnée</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="right money">
                  {transactions_in_range
                    ?.reduce((acc, curr) => acc + curr.PRIX_TTC, 0)
                    .toFixed(2)
                    .toLocaleString()}{' '}
                  €
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table_container big">
          <table>
            <thead>
              <tr>
                <th className="right">Nombre de transactions sur la période selectionnée</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="right money">{transactions_in_range?.length}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table_container big">
          <table>
            <thead>
              <tr>
                <th className="right">C.A. Total de l'année</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="right money">
                  {/* {transactions_in_range?.reduce((acc, curr) => acc + curr.PRIX_TTC, 0)} € */}
                  {isLoadingTotals
                    ? 'Calcul en cours...'
                    : transactionsTotals?.totalAmount.toFixed(2).toLocaleString()}{' '}
                  €
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table_container big">
          <table>
            <thead>
              <tr>
                <th className="right">Nombres de transactions de l'annéee</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td className="right money">{transactions_in_range?.length}</td> */}
                <td className="right money">
                  {isLoadingTotals ? 'Comptage en cours...' : transactionsTotals?.totalTransactions.toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <IonModal
        isOpen={isOpened}
        onDidDismiss={() => {
          setIsOpened(false)
          setSending(false)
        }}
        style={{
          height: '100%',
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton color="danger" onClick={() => setIsOpened(false)}>
                Fermer
              </IonButton>
            </IonButtons>
            <IonTitle></IonTitle>
            <IonButtons slot="end">
              <IonButton strong={true} onClick={sendEmail} color="primary" disabled={sending}>
                Envoyer
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>
            {/* <IonLabel position="stacked">Nom de l'éxploitant</IonLabel> */}
            <IonInput
              type="email"
              placeholder="Email client"
              value={email}
              onIonChange={(e: any) => {
                setEmail(e.detail.value)
              }}
            />
          </IonItem>
          <div
            style={{
              padding: '1rem',
            }}
          >
            <IonLabel position="stacked">Ticket</IonLabel>
            <pre
              style={{
                overflow: 'scroll',
                backgroundColor: 'white',
                color: 'black',
                borderRadius: '0.5rem',
                padding: '1rem',
              }}
            >
              {transactionToShow?.TICKET}
            </pre>
          </div>
        </IonContent>
      </IonModal>
    </div>
  )
})

export default Transactions
