import React from 'react'
import ExploitantsInfo from './ExploitantInfo'
import Logo from './Logo'

const Title: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Logo />
      <div>{title}</div>
      <ExploitantsInfo />
    </div>
  )
}

export default Title
