import { atom } from 'recoil'

interface UserT {
  ADRESSE: string
  ADRESSE2: string
  AFFICH_BT_PRO: boolean
  CODE_POSTAL: string
  EMAIL: string
  HOTLINE: string
  LANGUE_DEFAUT: number
  MONTANT_PREAUTO: number
  NOM: string
  PLBS: boolean
  PRIX: string
  PRIX_COM: string
  SIRET: string
  TAUX_TAXE: number
  TAXE_NOM: string
  TEL: string
  VILLE: string
  id: string
}

export type UserStateT = {
  token: string
  profile: UserT
}

export const user_state = atom<UserStateT>({
  key: 'user_state',
  default: JSON.parse(localStorage.getItem('user_state') || 'null'),
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newUserState) => {
        localStorage.setItem('user_state', JSON.stringify(newUserState))
      })
    },
  ],
})
