import logo from '../assets/logo.png'

const Logo = () => {
  return (
    <img
      src={logo}
      alt=""
      style={{
        width: '50px',
        marginRight: '30px',
      }}
    />
  )
}
export default Logo
