import 'react-datepicker/dist/react-datepicker.css'
import './Events.css'
import React, { memo, useState } from 'react'
import Loader from './Loader'
import { useAuthQuery } from '../helpers/http'
import { useRecoilState } from 'recoil'
import { filters_state } from '../states/filters.state'
import { IonToast, IonToggle } from '@ionic/react'

let Events: React.FC = memo(() => {
  let [showToast, setShowToast] = useState(false)
  let [toastMessage] = useState('')
  let [isAsc, setIsAsc] = useState(false)
  let [sortAttribute, setSortAttribute] = useState('horodatage')
  let [isRedemarrageVisible, setIsRedemarrageVisible] = useState(false)
  let [isDoorsForcedVisible, setIsDoorsForcedVisible] = useState(true)
  let [isServerErrorVisible, setServerErrorVisible] = useState(true)
  let [isControlPanelVisible, setIsControlPanelVisible] = useState(true)
  let [isTemperatureAlarmVisible, setIsTemperatureAlarmVisible] = useState(true)
  let [isDoorsStillOpenVisible, setIsDoorsStillOpenVisible] = useState(true)
  let [isScreenFreezingVisible, setIsScreenFreezingVisible] = useState(true)

  let [{ search }] = useRecoilState(filters_state)

  let { isLoading, isError, data: events } = useAuthQuery<any>('events', '/events')

  let filteredEvents = events?.filter((t: any) => {
    let show = false

    if (isRedemarrageVisible) {
      show = show || t.typeAlarm.includes('Redémarrage')
    }
    if (isDoorsForcedVisible) {
      show = show || t.typeAlarm.includes('porte forcée')
    }
    if (isServerErrorVisible) {
      show = show || t.typeAlarm.includes('email')
    }

    if (isControlPanelVisible) {
      show = show || t.typeAlarm.includes('compartiment technique ouvert')
    }

    if (isTemperatureAlarmVisible) {
      show = show || t.typeAlarm.includes('température')
    }

    if (isDoorsStillOpenVisible) {
      show = show || t.typeAlarm.includes('Portes ouvertes')
    }

    if (isScreenFreezingVisible) {
      show = show || t.typeAlarm.includes('freezing')
    }
    show = show && t.machine_location?.toLowerCase().includes(search?.toLowerCase())

    return show
  })

  let filteredEventsSorted = filteredEvents?.sort((a: any, b: any): any => {
    let valueA = a[sortAttribute].toString()
    let valueB = b[sortAttribute].toString()
    if (isAsc) {
      return valueA > valueB ? 1 : -1
    } else {
      return valueA > valueB ? -1 : 1
    }
  })

  if (isLoading) {
    return <Loader />
  }

  if (isError || !events) {
    return <div>Erreur</div>
  }

  // useEffect(()=>{filteredEvents()},[])

  return (
    <div className="tab_container  ">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000} // Change this value to control how long the toast shows for
      />
      <div className="tableWrapper">
        <div className="table_container tablemenuContainer">
          <table>
            <thead>
              <tr>
                <th
                  className="center"
                  onClick={() => {
                    setSortAttribute('horodatage')
                    setIsAsc(!isAsc)
                  }}
                >
                  Date
                </th>
                <th
                  className="center"
                  onClick={() => {
                    setSortAttribute('machineId')
                    setIsAsc(!isAsc)
                  }}
                >
                  Numéro
                </th>
                <th
                  className="center"
                  onClick={() => {
                    setSortAttribute('machine_location')
                    setIsAsc(!isAsc)
                  }}
                >
                  Localisation
                </th>
                <th
                  className="center"
                  onClick={() => {
                    setSortAttribute('typeAlarm')
                    setIsAsc(!isAsc)
                  }}
                >
                  Evènements
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredEventsSorted?.map((alerte: any) => {
                return (
                  <tr>
                    <td className="center"> {new Date(alerte.horodatage * 1000).toLocaleString()}</td>
                    <td className="center">{alerte.machineId}</td>
                    <td className="center">{alerte.machine_location}</td>
                    <td className="center">{alerte.typeAlarm}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div>
          <h1 className="center">Sélection des Alertes</h1>
          <div>
            <div className="alertSwitcher">
              <IonToggle
                onClick={() => {
                  setIsRedemarrageVisible(!isRedemarrageVisible)
                }}
                enableOnOffLabels={true}
                checked={isRedemarrageVisible}
              ></IonToggle>
              Redémarrage de la Machine
            </div>

            <div className="alertSwitcher">
              <IonToggle
                onClick={() => {
                  setIsDoorsForcedVisible(!isDoorsForcedVisible)
                }}
                enableOnOffLabels={true}
                checked={isDoorsForcedVisible}
              ></IonToggle>
              Porte Forcées
            </div>

            <div className="alertSwitcher">
              <IonToggle
                onClick={() => {
                  setIsTemperatureAlarmVisible(!isTemperatureAlarmVisible)
                }}
                enableOnOffLabels={true}
                checked={isTemperatureAlarmVisible}
              ></IonToggle>
              Alerte Température
            </div>

            <div className="alertSwitcher">
              <IonToggle
                onClick={() => {
                  setServerErrorVisible(!isServerErrorVisible)
                }}
                enableOnOffLabels={true}
                checked={isServerErrorVisible}
              ></IonToggle>
              Erreur Server Email
            </div>

            <div className="alertSwitcher">
              <IonToggle
                onClick={() => {
                  setIsControlPanelVisible(!isControlPanelVisible)
                }}
                enableOnOffLabels={true}
                checked={isControlPanelVisible}
              ></IonToggle>
              Compartiment Technique Ouvert
            </div>

            <div className="alertSwitcher">
              <IonToggle
                onClick={() => {
                  setIsDoorsStillOpenVisible(!isDoorsStillOpenVisible)
                }}
                enableOnOffLabels={true}
                checked={isDoorsStillOpenVisible}
              ></IonToggle>
              Portes Laissées Ouvertes
            </div>

            <div className="alertSwitcher">
              <IonToggle
                onClick={() => {
                  setIsScreenFreezingVisible(!isScreenFreezingVisible)
                }}
                enableOnOffLabels={true}
                checked={isScreenFreezingVisible}
              ></IonToggle>
              Freezing Ecran Suspecté
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Events
