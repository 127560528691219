import { IonSpinner } from '@ionic/react'

const Loader = () => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <IonSpinner color="secondary" />
  </div>
)

export default Loader
