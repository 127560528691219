import './Filters.css'
import React, { useCallback } from 'react'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { filters_state } from '../states/filters.state'
import { useRecoilState } from 'recoil'
import fr from 'date-fns/locale/fr'

// Register French locale
registerLocale('fr', fr)
// Set French as default locale
setDefaultLocale('fr')

const Filters: React.FC<{
  hideDatePicker?: boolean
}> = ({ hideDatePicker }) => {
  const [dateRange, setDateRange] = useRecoilState(filters_state)

  const maxDate = new Date()
  maxDate.setHours(0, 0, 0, 0) // Set hours, minutes, seconds, and milliseconds to zero

  const onClearDateRange = () => {
    setDateRange({ startDate: new Date(), endDate: null, search: '' })
  }
  const handleSearchQueryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDateRange({ startDate: dateRange.startDate, endDate: dateRange.endDate, search: e.target.value })
    },
    [dateRange.endDate, dateRange.startDate, setDateRange],
  )

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        width: '100%',
        padding: 10,
        alignItems: 'center',
        gap: 10,
      }}
    >
      <div className="search">
        <input
          type="search"
          placeholder="Recherche par localisation..."
          value={dateRange.search}
          onChange={handleSearchQueryChange}
        />
      </div>
      {!hideDatePicker && (
        <>
          <div>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              locale="fr"
              todayButton="Aujourd'hui"
              selectsRange={true}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              placeholderText="Sélectionner une période"
              maxDate={maxDate}
              onChange={(update) => {
                const [start, end] = update
                setDateRange({ startDate: start, endDate: end, search: dateRange.search })
              }}
            />
          </div>
          {(dateRange.startDate || dateRange.endDate || dateRange.search) && (
            <button
              style={{
                borderRadius: 5,
                padding: 5,
                height: '100%',
              }}
              onClick={onClearDateRange}
            >
              Effacer la sélection
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default Filters
