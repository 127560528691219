import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import Transactions from '../components/Transactions'
import './TransactionsTab.css'
import Filters from '../components/Filters'
import Title from '../components/Title'

const TransactionsTab: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <Title title="Transactions" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <Filters />
        <Transactions />
      </IonContent>
    </IonPage>
  )
}

export default TransactionsTab
