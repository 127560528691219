import { useRecoilState } from 'recoil'
import { user_state } from '../states/user.state'

const ExploitantsInfo: React.FC = () => {
  const [user] = useRecoilState(user_state)

  return (
    <span>
      {/* (#{user.profile.id} - {user.profile.NOM}) */}
      {user.profile.NOM}
    </span>
  )
}

export default ExploitantsInfo
